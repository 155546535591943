import * as React from "react"
import ReactMarkdown from 'react-markdown';
import { useState } from "react";
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import postalPdf from '../../static/media/postal-vote-application.pdf';
import proxyPDF from '../../static/media/proxy-vote-application-form.pdf';

import Seo from "../components/Seo"
import ProxyModal from "../components/proxy-votes";
import PostalPDFModal from "../components/PostalPDFModal";
import IconDownload from "../components/icons/IconDownload";
import SignUpSection from "../components/SignUpSection";

export default function HowCanIVote({ data }) {
  const voting = data.voting.nodes[0].frontmatter
  const ref = React.createRef();
  const [modalFormOpen, setModalFormOpen] = useState(false);
  const [modalProxyOpen, setModalProxyOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    e.target.id === 'pdf-form' && setModalFormOpen(true);
    e.target.id === 'proxy-modal' && setModalProxyOpen(true);
  }

  return(
    <>
      <Seo title = "How can I vote?" />

      { modalFormOpen && <PostalPDFModal setModalOpen={setModalFormOpen} />}
      { modalProxyOpen && <ProxyModal setModalOpen={setModalProxyOpen} />}

      <div className="page-content page-content--cards">

        <div className="container">

          <div className="row">
            <div className="col-md-8">
              <h1>How can I vote?</h1>
              <p>To vote in the upcoming March 2025 election, you must register by November 30, 2024. To request a vote, please visit our <Link to="/voting-workers">voter registration page for worker voters</Link>. If you are a resident voter, you can learn more <Link to="/voting-residents">here</Link>.</p>
            </div>
          </div>

          <div className="card">
            <div className="row">

              <div className="col-md-3">
                <StaticImage 
                  className=""
                  src="../../static/media/card-2.jpg" 
                  alt="" 
                  loading="eager"
                />
              </div>

              <div className="col-md-9">
                <div className="card__content">

                  <h2 className="card__headline">{voting.postal_vote_card.headline }</h2>
                  <ReactMarkdown className="card__text_div">{voting.postal_vote_card.text}</ReactMarkdown>
                  
                  <div className="card__buttons">
                    <div className="button-wrapper">
                      <button id="pdf-form" className="card__button" onClick={(e) => handleClick(e)}>Begin postal vote application</button>
                    </div>
                    <div className="button-wrapper">
                      <a className="card__button card__button--link" href={postalPdf} download><IconDownload fill="black"/> Download a blank postal vote application form (PDF)</a>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

          <div className="card">
            <div className="row">


              <div className="col-md-3">
                <StaticImage 
                  className=""
                  src="../../static/media/card-3.jpg" 
                  alt="" 
                  loading="eager"
                />
              </div>

              <div className="col-md-9">
                <div className="card__content">

                  <h2 className="card__headline">{ voting.proxy_voter_card.headline }</h2>
                  {/* <p className="card__text">The deadline for proxy vote applications has now passed, however if an unforeseen circumstance has emerged since 5pm on 16th March, it may be possible to appoint an emergency proxy voter. Please consult the Emergency Proxy forms at the bottom of this page on the Electoral Commission website, complete and send to <a href="mailto:electoralservices@cityoflondon.gov.uk">electoralservices@cityoflondon.gov.uk</a></p> */}
                  {/* <a className="card__button" href="https://www.electoralcommission.org.uk/i-am-a/voter/apply-vote-proxy#emergency-proxy-en" rel="noopener noreferrer" target="_blank">View Emergency Proxy application forms</a> */}
                  {/* <p className="card__text">If you are unable to get to the polling station, you can appoint someone else to vote on your behalf. <Link id="proxy-modal" onClick={(e) => handleClick(e)} to="/">If you apply to appoint a proxy voter</Link>, you must download, print and sign the form by hand - and email it to the <a href="mailto:electoralservices@cityoflondon.gov.uk">electoralservices@cityoflondon.gov.uk</a> by March 16th</p> */}
                  
                  <p className="card__text">If you are unable to get to the polling station, you can appoint someone else to vote on your behalf. <Link id="proxy-modal" onClick={(e) => handleClick(e)} to="/">If you apply to appoint a proxy voter</Link>, you must download, print and sign the form by hand - and email it to the <a href="mailto:electoralservices@cityoflondon.gov.uk">electoralservices@cityoflondon.gov.uk</a> by 5pm six working days before polling day for a new proxy vote or eleven working days for changes to an existing proxy vote.
                  </p>

                  <div className="card__buttons">
                    <div className="button-wrapper">
                      <button id="proxy-modal" className="card__button" onClick={(e) => handleClick(e)}>Begin proxy voter application</button>
                    </div>
                    <div className="button-wrapper">
                      <a className="card__button card__button--link" href={proxyPDF} download><IconDownload fill="black"/> Download the proxy vote application form (PDF)</a>
                    </div>
                    {/* <div className="button-wrapper">
                      <a className="card__button" href={proxyPDF} download><IconDownload /> Download the proxy vote application form (PDF)</a>
                    </div> */}
                  </div>

                </div>
              </div>

            </div>
          </div>

          <div className="card">
            <div className="row">


              <div className="col-md-3">
                <StaticImage 
                  className=""
                  src="../../static/media/card-1.jpg" 
                  alt="" 
                  loading="eager"
                />
              </div>

              <div className="col-md-9">
                <div className="card__content">

                  <h2 className="card__headline">{ voting.in_person_card.headline }</h2>

                  {/* <form className="card__form" action="">
                    <label htmlFor="">Where are you registered to vote?</label>
                    <div className="input-wrapper">
                      <input type="search" placeholder="Enter the name or address of your workplace or residence" name="" id="" />
                      <input type="submit" value="Find my ward" />
                    </div>
                  </form> */}
                  <ReactMarkdown className="card__text_div">{voting.in_person_card.text}</ReactMarkdown>
                  {/* <p className="card__text"><Link to="/#city-map">Click here to see a map of wards</Link></p> */}
                  <div className="button-wrapper">
                    <Link to="/find-your-ward" className="card__button">Find your Polling Station</Link>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        
      </div>

      <SignUpSection />
    </>
  )
}

// = = = = = = = = 
// GraphQL
export const data = graphql`
  query Voting { 
   voting: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/voting.md/"}}) {
    nodes{
      frontmatter {
        postal_vote_card {
          headline
          text
        }
        proxy_voter_card {
          headline
          text
        }
        in_person_card{
          headline
          text
        }
      }
    }
      
    }
  }
`;
